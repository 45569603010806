<template>
    <Dialog v-model:visible="show" :header="name" @hide="dialogHide">
        <div>
            <video ref="video" controls="false" style="width: 800px;"  autoplay >
            </video>
        </div>
    </Dialog>
</template>
  
<script>
import Hls from 'hls.js';

export default {
    data() {
        return {
            show: false,
            name: null,
            hls: null,
            url: ''
        };
    },
    methods: {
        initHls(data, name) {
            this.show = true
            this.name = name;
            var hls = new Hls();
            this.$nextTick(() => {
                if (Hls.isSupported()) {
                    hls.loadSource(this.url);
                    hls.attachMedia(this.$refs.video);
                    hls.on(Hls.Events.MANIFEST_PARSED, function () {
                        this.$refs.video.play();
                        console.log("---成功了");
                    });
                } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
                    this.$refs.video.src = this.url;
                    this.$refs.video.addEventListener('canplay', function () {
                        this.$refs.video.play();
                    });
                }
            })
        },
        dialogHide() {
            console.log("---dianjile");
            this.show = false
            // clearInterval(this.interval);
            // this.interval = null;
            // this.$emit("close", true);
        },
    },
};
</script>
<style scoped>
/* //全屏按钮 */
    video::-webkit-media-controls-fullscreen-button {
        display: none;
    }
    /* //播放按钮 */
    video::-webkit-media-controls-play-button {
        display: none;
    }
    /* //进度条 */
    video::-webkit-media-controls-timeline {
        display: none;
    }
    /* //观看的当前时间 */
    video::-webkit-media-controls-current-time-display{
        display: none;            
    }
    /* //剩余时间 */
    video::-webkit-media-controls-time-remaining-display {
        display: none;            
    }
    /* //音量按钮 */
    video::-webkit-media-controls-mute-button {
        display: none;            
    }
    video::-webkit-media-controls-toggle-closed-captions-button {
        display: none;            
    }
    /* //音量的控制条 */
    video::-webkit-media-controls-volume-slider {
        display: none;            
    }
    /* //所有控件 */
    video::-webkit-media-controls-enclosure{ 
        display: none;
    }
</style>