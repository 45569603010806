<template>
  <div v-if="show_bar" class="p-grid" style="margin-left: 1rem;width: calc(100% - 2rem);display: flex;justify-content: space-between">
    <Breadcrumb class="p-col-11" :home="breadcrumb.home" :model="breadcrumb.items"
                style="padding-top:0px;background: none;border: none;"/>
    <Button @click="$router.back()" label="返回" class="p-col-1 p-button-sm p-button-outlined p-button-secondary"
            style="padding:0px;width:65px;border:1px solid rgba(217, 217, 217, 1);margin-bottom:10px;"/>
  </div>
  <div class="card">
    <div class="p-d-flex p-flex-wrap" style="margin: 0 auto" :style="{width:id?'100%':'70%'}">
      <div class="p-col-6 p-d-flex p-flex-wrap">
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">规划名称：</label>
          <div class="p-col">{{ data && data.plan.name ? data.plan.name : '-' }}
            <span style="color: #12A25D;display: block;margin-top: 10px;cursor: pointer" @click="visibleLeft=true">查看项目</span>
          </div>
        </div>

        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">菌草品种：</label>
          <div class="p-col">{{ data && data.type ? data.type : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">种苗数量：</label>
          <div class="p-col">{{ data && data.num ? data.num : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">最佳种植时间：</label>
          <div class="p-col">{{ data && data.bestTime ? data.bestTime : '-' }}月份</div>
        </div>
      </div>
      <div class="p-col-6 p-d-flex p-flex-wrap">
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">专家：</label>
          <div class="p-col">{{ data&&data.expertName?data.expertName:'-' }}
          </div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">整地建议：</label>
          <div class="p-col">
            <div class="p-mb-2">行间距：{{ data && data.hangjianju ? data.hangjianju : '-' }}cm</div>
            <div class="p-mb-2">沟宽(穴直径)：{{ data && data.goukuan ? data.goukuan : '-' }}cm</div>
            <div class="p-mb-2">珠间距：{{ data && data.zhujianju ? data.zhujianju : '-' }}cm</div>
            <div class="p-mb-2">沟深(穴深)：{{ data && data.goushen ? data.goushen : '-' }}cm</div>
          </div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">栽培方式：</label>
          <div class="p-col">{{ data && data.method ? data.method : '-'
            }}
          </div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">劳力预估：</label>
          <div class="p-col">{{ data && data.laborForce ? (data.laborForce + '人/亩') : '-' }}</div>
        </div>
      </div>
      <div class="p-col-12 p-d-flex p-flex-wrap">
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">推荐肥料：</label>
          <div class="p-col">
            <div >{{ data && data.fertilizerType ? data.fertilizerType : '-' }}</div>
            <DataTable  class="p-mt-3" v-if="data && data.fertilizerList.length>0" :value="data.fertilizerList">
              <template #empty>
                暂无数据
              </template>
              <column header="肥料名称" field="name"></column>
              <column header="肥料类型" field="type"></column>
              <column header="肥料厂家" field="mfr"></column>
            </DataTable>
          </div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">选择服务：</label>
          <div class="p-col">{{ data && data.service ? data.service : '-' }}
            <DataTable class="p-mt-3" v-if="data && data.machineList.length>0" :value="data.machineList">
              <template #empty>
                暂无数据
              </template>
              <column header="机械名称" field="name"></column>
              <column header="机械类型" field="type"></column>
              <column header="肥料厂家" field="mfr"></column>
            </DataTable>
          </div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">传感器：</label>
          <div class="p-col">
            <DataTable  class="p-mt-3" v-if="data && data.sensorList.length>0" :value="data.sensorList">
              <template #empty>
                暂无数据
              </template>
              <column header="传感器名称" field="name"></column>
              <column header="传感器类型" field="type"></column>
              <column header="传感器厂家" field="mfr"></column>
            </DataTable>
          </div>
        </div>

      </div>
    </div>
    <Sidebar v-model:visible="visibleLeft" style="width: 700px;">
      <ShowProject :title="false" :back-but="false" :id="data==null?null:data.planId"></ShowProject>
    </Sidebar>
  </div>
</template>

<script>
import ShowProject from './showProject';

export default {
  name: 'showSuggest',
  components: {ShowProject},
  data() {
    return {
      breadcrumb: {
        home: {label: '种植项目', to: '#'},
        items: [
          {label: '项目管理', to: '/project/processing'},
          {label: '查看项目', to: '#'},
        ],
      },
      data: null,
      visibleLeft: false,
    };
  },
  props: {
    id: {
      default: null,
    },
    show_bar: {
      default: true,
    },
  },
  mounted() {
    if(this.id!=null||this.$route.query.id!=null){
      this.getData(this.id || this.$route.query.id);
    }
  },
  methods: {
    getData(id) {
      this.$axios.get('/suggest/getByPlanId', {params: {id: id}}).then(res => {
        this.data = res.data;
      });
    },
  },
};
</script>

<style scoped>
label{
  width: 130px;
  text-align: right;
}
</style>