<template>
  <div v-if="show_bar" class="p-grid" style="margin-left: 1rem;width: calc(100% - 2rem);display: flex;justify-content: space-between">
    <Breadcrumb class="p-col-11" :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;" />
    <Button @click="$router.push('/project/processing')" label="返回" class="p-col-1 p-button-sm p-button-outlined p-button-secondary"
      style="padding:0px;width:65px;border:1px solid rgba(217, 217, 217, 1);margin-bottom:10px;" />
  </div>
  <div class="card">
    <div class="p-d-flex p-flex-wrap" style="margin: 0 auto" :style="{width:id?'100%':'80%'}">
      <div class="p-col-6 p-d-flex p-flex-wrap">
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">项目编号：</label>
          <div class="p-col">{{ data && data.code ? data.code : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">项目名称：</label>
          <div class="p-col">{{ data && data.name ? data.name : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">项目圈地：</label>
          <div class="p-col">
            <show-map ref="showMap" style="height: 200px"></show-map>
          </div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">项目类型：</label>
          <div class="p-col">{{ data && data.type ? data.type : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">项目坐标：</label>
          <div class="p-col" style="width: calc(100% - 100px);overflow: hidden;text-overflow: ellipsis">{{ data && data.positionLon ? (data.positionLon + ',' + data.positionLat) : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">项目地点：</label>
          <div class="p-col">{{ data && data.country ? (data.country + data.province + data.city + data.district +
              data.location) : '-'
            }}
          </div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">项目目标：</label>
          <div class="p-col">{{ data && data.aim ? data.aim : '-' }}</div>
        </div>
      </div>

      <div class="p-col-6 p-d-flex p-flex-wrap" style="padding-left:5%">
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">实验效果：</label>
          <div class="p-col">{{ data && data.effect ? data.effect : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">亩产：</label>
          <div class="p-col">{{ data && data.yield ? (data.yield + '吨/亩') : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">种植面积：</label>
          <div class="p-col">{{ data && data.area ? (data.area + data.areaUnit) : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">整地条件：</label>
          <div class="p-col">{{ data && data.cond ? data.cond : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">灌溉条件：</label>
          <div class="p-col">{{ data && data.irrigation ? data.irrigation : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">灌溉费用：</label>
          <div class="p-col">{{ data && data.irrigationPrice ? (data.irrigationPrice + data.irrigationPriceUnit) : '-'
            }}
          </div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">土壤属性：</label>
          <div class="p-col">{{ data && data.soil ? (data.soil === '盐碱地' ? (data.soil + 'PH:' + data.soilPh + '含盐量：' +
              data.soilSalt + '电导率(EC):' + data.soilConductivity) : data.soil) : '-' }}
          </div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">用于生态治理：</label>
          <div class="p-col">{{ data && data.isEcoGovernance ? (data.isEcoGovernance === 0 ? '是' : '否') : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">生态治理目标：</label>
          <div class="p-col">{{ data && data.ecoGovernanceAim ? data.ecoGovernanceAim : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">菌草成熟后再利用：</label>
          <div class="p-col">{{ data && data.isReuse ? (data.isReuse === 0 ? '是' : '否') : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex" v-if="data&&data.isReuse===0">
          <label class="p-col-fixed">菌草成熟收获后的加工目标：</label>
          <div class="p-col">{{ data && data.procAim ? data.procAim : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">项目周期：</label>
          <div class="p-col">{{ data && data.endDate ? new Date(data.endDate).toLocaleDateString() : '-' }}</div>
        </div>
        <div class="p-col-12 p-d-flex">
          <label class="p-col-fixed">备注：</label>
          <div class="p-col">{{ data && data.comments ? data.comments : '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowMap from "@/views/modules/plan/showMap";

export default {
  name: "showProject",
  components: { ShowMap },
  data() {
    return {
      breadcrumb: {
        home: { label: "种植项目", to: "#" },
        items: [
          { label: "项目管理", to: "/project/processing" },
          { label: "查看项目", to: "#" },
        ],
      },
      data: null,
    };
  },
  props: {
    id: {
      default: null,
    },
    show_bar: {
      default: true,
    },
  },
  mounted() {
    this.getInfo(this.id || this.$route.query.id);
  },
  methods: {
    getInfo(id) {
      if (id) {
        console.log(id)
        this.$axios.get("/project/get", { params: { id: id } }).then((res) => {
          this.data = res.data;
          let info = {
            area: res.data.area,
            path: JSON.parse(res.data.areaMap),
            center: [res.data.positionLon, res.data.positionLat],
          };
          console.log(info);
          this.$nextTick(() => {
            this.$refs.showMap.update(info);
          });
        });
      }
    },
  },
};
</script>

<style scoped>
label {
  min-width: 150px;
  text-align: right;
}
.card {
  overflow-y: scroll;
}
.card::-webkit-scrollbar {
  display: none;
}
</style>