<template>
  <div class="p-grid" style="width: 100%;margin: 0;justify-content: space-between">
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"
                style="padding-top:0px;background: none;border: none;"/>
    <Button @click="back()" label="返回" class=" p-button-sm p-button-outlined p-button-secondary"
            style="padding:0px;width:65px;border:1px solid rgba(217, 217, 217, 1);margin-bottom:10px;"/>
  </div>

  <div style="display: flex;justify-content: space-between;">
    <div class="card" style="width: calc(50% - 7px);padding: 14px;margin: 0">
      <div style="width: 100%;display: flex;padding:6px">
        <div v-if="currProjData.isArchive === 0"
             style="width:32px;height:32px;background-color:rgba(18, 162, 93, 1);border-radius:50px;padding-left:9px;padding-top:7px;float:left;">
          <i style="color:#fff;font-size:14px;" class="iconfont icon-xiangmu1"></i>
        </div>
        <div v-else
             style="width:32px;height:32px;background-color:rgba(234, 84, 85, 1);border-radius:50px;padding-left:9px;padding-top:7px;float:left;">
          <i style="color:#fff;font-size:14px;" class="iconfont icon-xiangmu1"></i>
        </div>
        <div
          style="float:left;padding-left:6px;line-height:30px;font-size:20px;color:rgba(0, 0, 0, 0.65);font-weight:bold;">
          {{ currProjData ? currProjData.name : null }}
          <div style="line-height:22px;font-size: 14px;color: #12A25D;display:inline-block">【编号：{{
              currProjData ? currProjData.code : null
            }}】
          </div>
        </div>
      </div>

      <div style="width: 100%;padding:6px 0 6px 44px ;display: flex;flex-wrap: wrap">
        <div style="width:50%;line-height:40px;"><span class="field-name">项目类型：</span>{{
            currProjData ? currProjData.type : null
          }}
        </div>
        <div style="width:50%;" class="addr"
             v-tooltip.top="currProjData.province + currProjData.city + currProjData.district + currProjData.location">
          <span class="field-name">坐标：</span>
          {{ currProjData ? currProjData.positionLon + ',' + currProjData.positionLat : null }}
        </div>
        <div style="width:50%;line-height:40px;"><span class="field-name">种植面积：</span>{{
            currProjData ? currProjData.area : null
          }}
        </div>
        <div style="width:50%;line-height:40px;"><span class="field-name">亩产：</span>{{
            currProjData ? currProjData.yield : null
          }}
        </div>
        <div style="width:70%;line-height:40px;"><span class="field-name">项目周期：</span>{{
            currProjData ? new Date(currProjData.startDate).toLocaleDateString() + ' 至 ' +
              (currProjData.endDate ? new Date(currProjData.endDate).toLocaleDateString() : '进行中') : null
          }}
        </div>
        <div style="width:30%;line-height:40px;cursor: pointer;color:rgba(18, 162, 93, 1);"
             @click="$router.push({ path: '/showSuggest', query: { id: currProjData.planId } })">
          <i class="pi pi-eye p-mr-2"></i>专家建议
        </div>
      </div>
    </div>
    <div v-if="loading" class="card" style="width: calc(50% - 7px);display: flex;margin: 0;flex-wrap: wrap">
      <h5 style="width: 100%;font-size:16px;font-weight: 600;margin-bottom: 8px">未来天气</h5>
      <div style="width: 33.3%;">
        <div @click="currFocusWeatherTypeNum = '1'" class="WeatherCard "
             :class="{ 'border-green': currFocusWeatherTypeNum == '1' }">
          <img
            :src="'/assets/images/weather/' + (currWeather?.result?.data[1].condition ? currWeather?.result?.data[1].condition : 'noweatherdata') + '.png'"/>
          <div style="padding:0.5rem 0">
            <div style="color:rgba(0, 0, 0, 0.45);font-size:14px;">
              {{
                currWeather ? (currWeather?.result?.data[1].condition !== null && currWeather?.result?.data[1].condition
                !== '' ? currWeather?.result?.data[1].condition : '/') : null
              }}（24小时）
            </div>
            <div style="color: rgba(0, 0, 0, 0.85);font-size:16px;">{{
                currWeather ? (currWeather?.result?.data[1].apparent_temperature_min.Value !== null &&
                currWeather?.result?.data[1].apparent_temperature_min.Value !== '' ?
                  currWeather?.result?.data[1].apparent_temperature_min.Value : '/') : null
              }} -
              {{
                currWeather ? (currWeather?.result?.data[1].apparent_temperature_max.Value !== null &&
                currWeather?.result?.data[1].apparent_temperature_max.Value !== '' ?
                  currWeather?.result?.data[1].apparent_temperature_max.Value : '/') : null
              }} ℃
            </div>
          </div>
        </div>
        <div class="dateButton"
             :class="{ 'day-highlight': currFocusWeatherTypeNum == '1', 'day-normal': currFocusWeatherTypeNum != '1' }">
          {{ getAfterDay(1) }}
        </div>
      </div>
      <div style="width: 33.3%;">
        <div @click="currFocusWeatherTypeNum = '2'" class="WeatherCard"
             :class="{ 'border-green': currFocusWeatherTypeNum == '2' }">
          <img
            :src="'/assets/images/weather/' + (currWeather?.result?.data[2].condition ? currWeather?.result?.data[2].condition : 'noweatherdata') + '.png'"/>
          <div style="padding:0.5rem 0">
            <div style="color:rgba(0, 0, 0, 0.45);font-size:14px;">
              {{ currWeather?.result?.data[2].condition ? currWeather?.result?.data[2].condition : '/' }}（48小时）
            </div>
            <div style="color: rgba(0, 0, 0, 0.85);font-size:16px;">{{
                currWeather ? (currWeather?.result?.data[2].apparent_temperature_min.Value !== null &&
                currWeather?.result?.data[2].apparent_temperature_min.Value !== '' ?
                  currWeather?.result?.data[2].apparent_temperature_min.Value : '/') : null
              }} -
              {{
                currWeather ? (currWeather?.result?.data[2].apparent_temperature_max.Value !== null &&
                currWeather?.result?.data[2].apparent_temperature_max.Value !== '' ?
                  currWeather?.result?.data[2].apparent_temperature_max.Value : '/') : null
              }} ℃
            </div>
          </div>
        </div>
        <div class="dateButton"
             :class="{ 'day-highlight': currFocusWeatherTypeNum == '2', 'day-normal': currFocusWeatherTypeNum != '2' }">
          {{ getAfterDay(2) }}
        </div>
      </div>
      <div style="width: 33.3%;">
        <div @click="currFocusWeatherTypeNum = '3'" class="WeatherCard"
             :class="{ 'border-green': currFocusWeatherTypeNum == '3' }">
          <img
            :src="'/assets/images/weather/' + (currWeather?.result?.data[3].condition ? currWeather?.result?.data[3].condition : 'noweatherdata') + '.png'"/>
          <div class="p-col-8" style="cursor:pointer;padding:0.5rem 0">
            <div style="color:rgba(0, 0, 0, 0.45);font-size:14px;">
              {{ currWeather?.result?.data[3].condition ? currWeather?.result?.data[3].condition : '/' }}（72小时）
            </div>
            <div style="color: rgba(0, 0, 0, 0.85);font-size:16px;">{{
                currWeather ? (currWeather?.result?.data[3].apparent_temperature_min.Value !== null &&
                currWeather?.result?.data[3].apparent_temperature_min.Value !== '' ?
                  currWeather?.result?.data[3].apparent_temperature_min.Value : '/') : null
              }} -
              {{
                currWeather ? (currWeather?.result?.data[3].apparent_temperature_max.Value !== null &&
                currWeather?.result?.data[3].apparent_temperature_max.Value !== '' ?
                  currWeather?.result?.data[3].apparent_temperature_max.Value : '/') : null
              }} ℃
            </div>
          </div>
        </div>
        <div class="dateButton"
             :class="{ 'day-highlight': currFocusWeatherTypeNum == '3', 'day-normal': currFocusWeatherTypeNum != '3' }">
          {{ getAfterDay(3) }}
        </div>
      </div>
    </div>
    <div v-if="!loading" class="card" style="width: calc(50% - 7px);margin: 0;flex-wrap: wrap">
      <div class="loading">
        <div class="loader"></div>
      </div>
    </div>
  </div>

  <div style="margin-left:0px;width:100%;margin-top:20px;display: flex;justify-content: space-between;flex-wrap: wrap">
    <!--摄像头设备-->
    <div v-if="currVideoDev.length === 1" class="card p-col-3"
         style="height:157px;margin-right:10px;padding:0px;position: relative">
      <img v-if="currDevicePhotoUrl[0]" style="width:100%;height: 100%;border-radius: 3px"
           :src="currDevicePhotoUrl[0]"/>
      <div v-if="!currDevicePhotoUrl[0]" style="height:140px;text-align:center;">
        <ProgressSpinner style="margin-top:50px;width:32px;height:32px" strokeWidth="6" animationDuration=".5s"/>
        正在抓取图像...
      </div>
      <div
        style="position:absolute;border-radius: 0 0 3px 3px; bottom:0;width:100%;left:0px;background-color: rgba(0, 0, 0, 0.65);text-align: center;">
        <Button v-if="currVideoDev[0].status == 0" style="color:rgba(18, 162, 93, 1);color:#FFFFFF;"
                @click="openVideo(currVideoDev[0])" label="播放视频" class="p-button-link p-button-sm"/>
        <Button v-else disabled="disabled" style="color:rgba(18, 162, 93, 1);color:#FFFFFF;" label="播放视频"
                class="p-button-link p-button-sm"/>
        <div style="background: #FFFFFF;width:1px;height: 10px;margin-bottom:6px;display: inline-block"></div>
        <Button style="color:rgba(18, 162, 93, 1);color:#FFFFFF;" label="采样回放"
                @click="picturePlaybackShow(currVideoDev[0])" class="p-button-link p-button-sm"/>
      </div>
    </div>

    <div class="card " style="height:157px;padding:20px;margin-right: 10px"
         :style="{ 'margin-right': currVideoDev.length > 0 ? '10px' : '' }"
         :class="[currVideoDev.length === 1 ? 'c3' : 'c2']">
      <div class="title">目标：{{ currProjData ? currProjData.aim : null }}</div>
      <div class="content">{{ currProjData ? currProjData.effect : null }}</div>
      <Button @click="viewDetail()" icon="pi pi-eye" style="color:rgba(18, 162, 93, 1);padding:0px;margin-top:10px;"
              label="查看详情" class="p-button-link p-button-sm"/>
    </div>
    <div class="card  p-grid" style="height:157px;margin: 0;display:flex;padding:0"
         :class="[currVideoDev.length === 1 ? 'c3' : 'c2']">
<!--       <show-map ref="showMap" :update-but="false"></show-map>-->

      <show-map ref="showMap" style="height:100%;width:40%;"></show-map>

      <div style="height:100%;width:60%;padding:10px 20px">
        <p style="height:33%;margin:4% 0 0">菌草品种：{{ info ? info.type : null }}</p>
        <p style="height:33%;margin:0">种苗数量：{{ info ? info.num : null }} 株</p>
        <p style="height:33%;margin:0">产量预估：{{ info ? info.yield : null }} 吨/亩</p>
      </div>
    </div>
    <!--摄像头设备-->
    <div v-if="currVideoDev.length > 1" style="position:relative;width:100%;">
      <i v-if="currVideoDev.length > 4"
         style="padding:0.3rem;color:#12A25D;z-index:99;background:#f2f5f9;border-radius:50%;font-size:1.5rem;cursor:pointer;position:absolute;left:10px;top:60px"
         class="pi pi-angle-left" @click="previousDev"></i>
      <div id="dev" style="z-index:98;width:100%;height:185px;display: flex;overflow-x:scroll;flex-wrap:nowrap;">
        <div v-for="(dev, index) in currVideoDev" :key="index" :id="'dev' + index" class="card"
             style="min-width:24%;max-width:24%;height:157px;padding:0;position: relative;margin-right:calc(4%/3)">
          <img v-if="currDevicePhotoUrl[index]" style="width:100%;height: 100%;border-radius: 3px"
               :src="currDevicePhotoUrl[index]"/>
          <div v-if="!currDevicePhotoUrl[index]" style="height:140px;text-align:center;">
            <ProgressSpinner style="margin-top:50px;width:32px;height:32px" strokeWidth="6" animationDuration=".5s"/>
            正在抓取图像...
          </div>
          <div
            style="position:absolute;border-radius: 0 0 3px 3px; bottom:0rem;width:100%;left:0px;background-color: rgba(0, 0, 0, 0.65);text-align: center;">
            <Button v-if="dev.status == 0" style="color:rgba(18, 162, 93, 1);color:#FFFFFF;" @click="openVideo(dev)"
                    label="播放视频" class="p-button-link p-button-sm"/>
            <Button v-else disabled="disabled" style="color:rgba(18, 162, 93, 1);color:#FFFFFF;" label="播放视频"
                    class="p-button-link p-button-sm"/>
            <div style="background: #FFFFFF;width:1px;height: 10px;margin-bottom:6px;display: inline-block"></div>
            <Button style="color:rgba(18, 162, 93, 1);color:#FFFFFF;" label="采样回放" @click="picturePlaybackShow(dev)"
                    class="p-button-link p-button-sm"/>
          </div>
          <div style="padding:0.3rem 0;margin-top:-0.3rem;text-align:center;width:100%;background:white">
            {{ dev ? dev.dname : null }}<span>{{ dev ? (dev.status == 1 ? '（历史设备）' : '') : null }}</span></div>
        </div>
      </div>
      <i v-if="currVideoDev.length > 4"
         style="padding:0.3rem;color:#12A25D;z-index:99;background:#f2f5f9;border-radius:50%;font-size:1.5rem;cursor:pointer;position:absolute;right:10px;top:60px"
         class="pi pi-angle-right" @click="nextDev"></i>
    </div>

  </div>

  <div class="card p-d-flex p-justify-between"
       :style="{ padding: currentModule === 0 ? '1rem 3rem' : '1rem 3rem 0 3rem' }"
       style="margin-bottom: 0px;border-bottom-right-radius: 0;border-bottom-left-radius: 0;margin-top:1rem">
    <ul class="selectUl">
      <li :class="{ 'selectedModule': currentModule === 0 }" @click="selectModule(0)">项目数据展示</li>
      <li :class="{ 'selectedModule': currentModule === 1 }" @click="selectModule(1)">整地准备</li>
      <li :class="{ 'selectedModule': currentModule === 2 }" @click="selectModule(2)">种植记录</li>
      <li :class="{ 'selectedModule': currentModule === 3 }" @click="selectModule(3)">生长记录</li>
      <li :class="{ 'selectedModule': currentModule === 4 }" @click="selectModule(4)">测产记录</li>
      <li :class="{ 'selectedModule': currentModule === 5 }" @click="selectModule(5)">收割管理</li>
    </ul>
    <!--    <Button v-if="currentModule!==0" @click="toFrom">记录</Button>-->
  </div>

  <div v-if="currentModule === 0">
    <!--  图表显示区域-->
    <div v-show="loadingOver">
      <div v-bind:key="item" v-for="(item) of currDevData" class="card"
           style="width: 100%;margin-top:6px;display: flex;flex-wrap: wrap; position: relative;padding: 0">
        <div style="width: 100%;padding:10px;border-bottom: 1px solid #d9d9d9;">
          <label style="font-size:16px;line-height:32px;color:rgba(51, 51, 51, 1);font-weight:bold;">{{
              item.dname + ' - ' + item.dtype
            }}<span>{{ item.status == 1 ? '（历史设备）' : '' }}</span> -
            <span>{{ kpiName[item.did] }}</span>
            <!--            <span v-if="firstSign>12">{{kpiName[item.did]}}</span><span v-if="firstSign<=12">{{item.kpiName[0]?.cname}}</span>-->
          </label>
          <Button @click="exportCSV(item.pid + '-' + item.did)" label="下载"
                  style="float:right;width:61px;height:32px;border: 1px solid #D9D9D9;color:rgba(0, 0, 0, 0.65);font-size:14px;"
                  class="p-button-outlined p-button-secondary"/>
        </div>
        <div class="scrollBar" :id="'kpilist_' + item.pid + '_' + item.did">

          <table v-bind:key="kpi" v-for="kpi of item.kpiName?.slice(0, 5)"
                 @click="drawByKpi(item, kpi.ename, kpi.cname, kpi.valueUnit)" border="0"
                 :style="{ 'border-right': currKpiName[item.pid + '-' + item.did] == kpi.ename ? '3px solid rgba(18, 162, 93, 1)' : '' }"
                 style="cursor:pointer; width: 100%;height: 70px;" cellspacing="0" cellpadding="10">
            <tr>
              <td align="right">
                <div style="width:40px;height:40px;background-color:#EFF2F6;border-radius:40px;padding:10px 10px 0 0">
                  <i
                    :style="{ 'color': currKpiName[item.pid + '-' + item.did] == kpi.ename ? 'rgba(18, 162, 93, 1)' : 'rgba(51, 51, 51, 1)' }"
                    style="font-size:20px;" :class="'iconfont ' + kpi.icon"></i>
                </div>
              </td>
              <td align="left">
                <div style="width:97px;font-weight: bold;font-size:18px;color:rgba(0, 0, 0, 0.85);">
                  {{ kpi.newValue }}
                  <label style="font-size:14px;color:rgba(0, 0, 0, 0.45);font-weight:normal;">{{
                      kpi.valueUnit
                    }}</label>
                </div>
                <div style="color:rgba(0, 0, 0, 0.45);font-size:14px;">
                  {{ kpi.avgValue ? '最新' + kpi.cname + '均值' : kpi.cname }}
                </div>
              </td>
            </tr>
          </table>
          <div v-if="more[item.pid + '_' + item.did]" class="more">
            <table v-bind:key="kpi" v-for="kpi of item.kpiName?.slice(5)"
                   @click="drawByKpi(item, kpi.ename, kpi.cname, kpi.valueUnit)" border="0"
                   :style="{ 'border-right': currKpiName[item.pid + '-' + item.did] == kpi.ename ? '3px solid rgba(18, 162, 93, 1)' : '' }"
                   style="cursor:pointer; width: 50%;height: 80px;" cellspacing="0" cellpadding="10">
              <tr>
                <td align="right">
                  <div style="width:40px;height:40px;background-color:#EFF2F6;border-radius:40px;padding:10px 10px 0 0">
                    <i
                      :style="{ 'color': currKpiName[item.pid + '-' + item.did] == kpi.ename ? 'rgba(18, 162, 93, 1)' : 'rgba(51, 51, 51, 1)' }"
                      style="font-size:20px;" :class="'iconfont ' + kpi.icon"></i>
                  </div>
                </td>
                <td align="left">
                  <div style="width:100px;font-weight: bold;font-size:18px;color:rgba(0, 0, 0, 0.85);">
                    {{ kpi.newValue }}
                    <label style="font-size:14px;color:rgba(0, 0, 0, 0.45);font-weight:normal;">{{
                        kpi.valueUnit
                      }}</label>
                  </div>
                  <div style="color:rgba(0, 0, 0, 0.45);font-size:14px;">{{ kpi.cname }}</div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div v-tooltip.top="'查看更多'" v-if="item.kpiName?.length > 5" @click="showMore(item.pid + '_' + item.did)"
             class="omitIco">
          ...
        </div>
        <div :id="'main_' + item.pid + '_' + item.did" style="width:calc(100% - 190px);padding-top:15px;height:390px;">
        </div>
      </div>
    </div>
    <div v-if="projectDataShow" v-show="!loadingOver" style="text-align:center;margin-top:60px;">
      <img style="width:128px;" src="../../../../assets/images/empty.png"/>
      <p style="font-size:14px;color:rgba(0, 0, 0, 0.45);">正在加载设备信息~</p>
    </div>
    <div v-if="!projectDataShow">
      <div style="text-align:center;margin-top:60px;">
        <img style="width:128px;" src="../../../../assets/images/empty.png"/>
        <p style="font-size:14px;color:rgba(0, 0, 0, 0.45);">没有找到设备信息~</p>
      </div>
      <!-- <div v-if="!show">
        <ProgressSpinner />
      </div> -->
    </div>

  </div>
  <div @click="showChat()" class="chatIcon" v-if="userDisplay">
    <img style="height: 30px" src="@/assets/images/expert.png"/>
    <p style="text-align: center;color: #FFFFFF;width: 100%;font-size: 10px">专家指导</p>
  </div>

  <soilpre-record ref="record" :projectId="$route.query.projId" :commentList="commentList" :suggestList='suggestList'
                  v-if="currentModule === 1" @comment="showComment" @suggest="showSuggestComment"
                  :buttonsInfo='buttons'>
  </soilpre-record>
  <plant-record ref="record" :projectId="$route.query.projId" :comment-list="commentList" :suggestList='suggestList'
                v-if="currentModule === 2" @comment="showComment" @suggest="showSuggestComment" :buttonsInfo='buttons'>
  </plant-record>
  <grow-record ref="record" :projectId="$route.query.projId" :comment-list="commentList" :suggestList='suggestList'
               v-if="currentModule === 3" @comment="showComment" @suggest="showSuggestComment" :buttonsInfo='buttons'>
  </grow-record>
  <ass-record ref="record" :projectId="$route.query.projId" :comment-list="commentList" :suggestList='suggestList'
              v-if="currentModule === 4" @comment="showComment" @suggest="showSuggestComment" :buttonsInfo='buttons'>
  </ass-record>
  <harves-record ref="record" :projectId="$route.query.projId" :comment-list="commentList" :suggestList='suggestList'
                 v-if="currentModule === 5" @comment="showComment" @suggest="showSuggestComment" :buttonsInfo='buttons'>
  </harves-record>

  <add-comment ref="comment" v-if="commentDisplay" @close="getComment"></add-comment>
  <add-suggest ref="suggest" v-if="suggestDisplays" @close="getSuggestComment"></add-suggest>

  <Toast/>

  <OverlayPanel style="width:540px;" ref="op" :dismissable="true">
    <p>
      目标：{{ currProjData ? currProjData.aim : null }}
    </p>
    <p>
      实验效果：{{ currProjData ? currProjData.effect : null }}
    </p>
  </OverlayPanel>

  <PicturePlayback @close="picClose" ref="picturePlayback" v-if="picturePlayback"></PicturePlayback>
  <jfPlayVideo @close='jfPicClose' ref='jfPlayVideo' v-if="jfPlayVideo"></jfPlayVideo>
  <ESVideo ref="ysVideo" v-if="videoDisplay" v-on:close="closeVideo"></ESVideo>
  <TYVideo @close='tyPicClose' ref='tyPlayVideo' v-if="tyPlayVideo"></TYVideo>
</template>

<script>
import ESVideo from "@/views/modules/data/overview/ESVideo";
import TYVideo from "@/views/modules/data/overview/TYVideo";
import PicturePlayback from "@/views/modules/data/overview/PicturePlayback";
import jfPlayVideo from "@/views/modules/data/overview/jfPlayVideo";
import PlantRecord from "@/views/modules/data/overview/plant/plantRecord";
import AssRecord from "@/views/modules/data/overview/ass/assRecord";
import HarvesRecord from "@/views/modules/data/overview/harvest/harvestRecord";
import SoilpreRecord from "@/views/modules/data/overview/soilpre/soilpreRecord";
import GrowRecord from "@/views/modules/data/overview/grow/growRecord";
import ChatWindow from "@/views/modules/chat/chatWindow";
import ShowMap from "@/views/modules/plan/showMap";
import addComment from "@/views/modules/data/overview/addComment";
import AddSuggest from "@/views/modules/data/overview/addSuggest";

export default {
  components: {
    HarvesRecord,
    AssRecord,
    SoilpreRecord,
    PlantRecord,
    PicturePlayback,
    jfPlayVideo,
    ESVideo,
    GrowRecord,
    ChatWindow,
    ShowMap,
    addComment,
    AddSuggest,
    TYVideo
  },
  data() {
    return {
      loading: false,
      firstSign: 0,
      kpiName: [],
      length: null,
      commentDisplay: false,
      suggestDisplays: false,
      loadingOver: false,
      projectDataShow: true,
      show: true,
      info: [],
      videoDisplay: false,
      more: {},
      currProjData: {},
      currWeather: {}, //天气数据可能为空，因此默认值为{}，而不是null
      currFocusWeatherTypeNum: "1", // 第二行右侧所显示的天气信息
      currDevData: {}, // 当前项目的关联设备信息
      currVideoDev: [], // 当前包含的摄像头设备
      currDevicePhotoUrl: [],
      breadcrumb: {
        home: { label: "种植项目", to: "#" },
        items: [
          { label: "项目概览", to: "/data/overview" },
          { label: "查看数据", to: "#" },
        ],
      },
      echarts: window["echarts"],
      currKpiName: {},
      picturePlayback: false,
      jfPlayVideo: false,
      currChartOption: {},
      currentModule: 0,
      chatDisplay: false,
      userDisplay: false,
      commentList: [],
      suggestList: [],
      buttons: {
        xznsBt: false,
        xgnsBt: false,
        scnsBt: false,
        xzplBt: false,
        xzjyBt: false,
      },
      tyPlayVideo: false
    };
  },
  mounted() {
    if (localStorage.getItem("menuList")) {
      JSON.parse(localStorage.getItem("menuList")).forEach((item, index) => {
        if (
          item.name == "种植项目" &&
          JSON.parse(localStorage.getItem("menuList"))[index].children
        ) {
          JSON.parse(localStorage.getItem("menuList"))[index].children.forEach(
            (v, i) => {
              if (
                v.name == "项目概览" &&
                JSON.parse(localStorage.getItem("menuList"))[index].children[i]
                  .buttons
              ) {
                JSON.parse(localStorage.getItem("menuList"))[index].children[
                  i
                  ].buttons.forEach((a) => {
                  switch (a.name) {
                    case "新增农事记录":
                      this.buttons.xznsBt = true;
                      break;
                    case "修改农事记录":
                      this.buttons.xgnsBt = true;
                      break;
                    case "删除农事记录":
                      this.buttons.scnsBt = true;
                      break;
                    case "新增评论":
                      this.buttons.xzplBt = true;
                      break;
                    case "新增建议":
                      this.buttons.xzjyBt = true;
                      break;
                    default:
                      return;
                  }
                });
              }
            }
          );
        }
      });
    }
    const projectId = this.$route.query.projId;
    let userInfo = JSON.parse(localStorage.getItem("userinfo"));
    this.$axios
      .get("/proj_user_ref/getProjectUsers", {
        params: {
          id: projectId,
        },
      })
      .then((res) => {
        res.data.forEach((item) => {
          if (item.userId == userInfo.user.id) {
            this.userDisplay = true;
          }
        });
      });
    this.loadData();
    this.currentModule = parseInt(this.$route.query.cm, 10) || 0;
    // console.log(this.currentModule);
    this.getComment();
  },
  beforeRouteLeave(to, from, next) {
    next(localStorage.removeItem("suggestDisplay"));
  },
  watch: {
    $route() {
      this.firstSign = 0;
      this.loadData();
    },
  },
  methods: {
    previousDev() {
      let div = document.getElementById("dev0");
      const width = document.getElementById("dev").clientWidth;
      let length = this.length;
      if (div.offsetLeft < -10 && length > 0) {
        length--;
        let left = div.style.marginLeft;
        left = left == "" ? 0 : parseInt(left);
        let step = ((24 * width) / 100 + (4 * width) / 300) / 100;
        let time = setInterval(() => {
          let lefts = div.style.marginLeft;
          lefts = lefts == "" ? 0 : parseInt(lefts);
          lefts += step;
          div.style.marginLeft = lefts - 2 + "px";
          if (
            parseInt(lefts - left) >
            parseInt((24 * width) / 100 + (4 * width) / 300)
          ) {
            // let marginLeft = div.style.marginLeft;
            // marginLeft = marginLeft == "" ? 0 : parseInt(marginLeft);
            // div.style.marginLeft = marginLeft + (4 * width) / 300 + "px";
            clearInterval(time);
          }
        }, 5);
      }
    },
    nextDev() {
      let div = document.getElementById("dev0");
      const width = document.getElementById("dev").clientWidth;
      let length = this.length;
      if (
        div.offsetLeft >=
        10 - this.length * ((24 * width) / 100 + (4 * width) / 300) &&
        length > 0
      ) {
        length--;
        let left = div.style.marginLeft;
        left = left == "" ? 0 : parseInt(left);
        let step = ((24 * width) / 100 + (4 * width) / 300) / 100;
        let time = setInterval(() => {
          let lefts = div.style.marginLeft;
          lefts = lefts == "" ? 0 : parseInt(lefts);
          lefts -= step;
          div.style.marginLeft = lefts + 1 + "px";
          if (
            parseInt(left - lefts) >
            parseInt((24 * width) / 100 + (4 * width) / 300)
          ) {
            // let marginLeft = div.style.marginLeft;
            // marginLeft = marginLeft == "" ? 0 : parseInt(marginLeft);
            // div.style.marginLeft = marginLeft - (4 * width) / 300 + "px";
            clearInterval(time);
          }
        }, 5);
      }
    },
    getComment() {
      this.commentDisplay = false;
      this.$axios
        .get("/rec_comment/list", {
          params: {
            projectId: this.$route.query.projId,
          },
        })
        .then((res) => {
          this.commentList = res.data;
          this.$nextTick(() => {
            if (this.$refs.record && this.$refs.record.length === 1) {
              this.$refs.record.commentList = res.data;
            }
          });
        });
    },
    getSuggestComment() {
      this.suggestDisplays = false;
      let type = null;
      switch (this.currentModule) {
        case 1:
          type = "整地准备";
          break;
        case 2:
          type = "种植记录";
          break;
        case 3:
          type = "生长记录";
          break;
        case 4:
          type = "测产记录";
          break;
        case 5:
          type = "收割管理";
          break;
        default:
          type = null;
      }
      this.$axios
        .get("/rec_suggest_comment/list", {
          params: {
            projectId: this.$route.query.projId,
            type: type,
          },
        })
        .then((res) => {
          this.suggestList = res.data;
          this.$nextTick(() => {
            if (this.$refs.record) {
              this.$refs.record.suggestList = res.data;
            }
          });
        });
    },
    showComment(data) {
      data.projectId = this.$route.query.projId;
      this.commentDisplay = true;
      this.$nextTick(() => {
        this.$refs.comment.init(data);
      });
    },
    showSuggestComment(data) {
      this.suggestDisplays = true;
      this.$nextTick(() => {
        this.$refs.suggest.init(data);
      });
    },
    showChat() {
      this.$vuexData.commit("updata");
    },
    getSuggest(id) {
      this.$axios
        .get("/suggest/getByPlanId", { params: { id: id } })
        .then((res) => {
          this.info = res.data;
          let path = null;
          try {
            path = JSON.parse(this.info.plan.areaMap);
          } catch (e) {
          }
          let infos = {
            path: path,
            area: this.info ? this.info.plan.area : null,
            center: [
              this.currProjData.positionLon,
              this.currProjData.positionLat,
            ],
          };
          this.$nextTick(() => {
            if(this.$refs.showMap) {
              this.$refs.showMap.update(infos);
            }
          });
          for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i).indexOf("suggestInfo") > -1) {
              localStorage.removeItem(localStorage.key(i));
            }
          }
          localStorage.setItem(
            "suggestInfo" + this.$route.query.projId,
            JSON.stringify(res.data)
          );
        });
    },

    // toFrom() {
    //   let urls = [
    //     "",
    //     "/soilpre/land",
    //     "/plant/plant",
    //     "/grow/emergence",
    //     "/ass/ass",
    //     "/harvest/harvest",
    //   ];
    //   this.$router.push({
    //     path: urls[this.currentModule],
    //     query: { projId: this.$route.query.projId },
    //   });
    // },
    selectModule(value) {
      this.currentModule = value;
      this.loadData();
      if (this.currentModule != 0) {
        this.getSuggestComment();
      }
    },
    getAfterDay(num) {
      let date = new Date(new Date().getTime() + num * 24 * 60 * 60 * 1000);
      return date.getMonth() + 1 + "月" + date.getDate() + "日";
    },
    exportCSV(fullId) {
      let ownOption = this.currChartOption[fullId];

      let content = "时间,参数,";
      for (let i in ownOption.legend.data) {
        let curr = ownOption.legend.data[i];
        content += curr + ",";
      }
      content += "\n";

      for (let i in ownOption.xAxis.data) {
        content += ownOption.xAxis.data[i] + ","; // 时间
        content += ownOption.cname + ","; // 指标名称
        for (let j in ownOption.series) {
          let ser = ownOption.series[j];
          if (ser.data.length > 0) {
            let value = ser.data[i];
            content += (value ? value : "") + ",";
          }
        }
        content += "\n";
      }

      // 创建Blob对象 传入一个合适的MIME类型
      const blob = new Blob(["\ufeff" + content], {
        type: "text/csv,charset=UTF-8",
      });
      // 使用 Blob 创建一个指向类型化数组的URL
      const csvUrl = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.download =
        fullId + "_" + ownOption.cname + "_" + new Date().getTime() + ".csv"; //文件名字
      link.href = csvUrl;
      link.click();
    },
    scrollKpiNameList(kpiListEleId) {
      let content = document.getElementById(kpiListEleId);
      let scrollStep = 300;
      let sl = content.scrollLeft,
        cw = content.scrollWidth;
      if (sl + scrollStep >= cw) {
        content.scrollTo(cw, 0);
      } else {
        content.scrollTo(sl + scrollStep, 0);
      }
    },
    picturePlaybackShow(dev) {
      // 获取摄像头参数
      this.$http(
        "/devconf/getConf",
        "get",
        {
          devPid: dev.pid,
          devDid: dev.did,
        },
        (resp2) => {
          console.log(resp2, "-----resp2");
          if (resp2.data.length == 0) {
            this.$toast.add({
              severity: "warn",
              summary: "警告",
              detail: "无可回放的资源！",
              life: 3000,
            });
          } else {
            this.picturePlayback = true;
            this.$nextTick(function() {
              //海康威视
              if (dev.pid == "webcam") {
                this.$refs.picturePlayback.init(
                  this.lookupConfItem(resp2.data, "serial_num")
                );
              }
              //极飞
              if (dev.pid == "jf_webcam") {
                this.$refs.picturePlayback.init(
                  this.lookupConfItem(resp2.data, "jf_serial_num")
                );
              }
              //涂鸦
              if (dev.pid == "tuya_webcam") {
                this.$refs.picturePlayback.init(
                  this.lookupConfItem(resp2.data, "tuya_serial_num")
                );
              }
            });
          }

        }
      );
    },
    showMore(devfullid) {
      if (this.more[devfullid]) {
        this.more[devfullid] = false;
      } else {
        this.more[devfullid] = true;
      }
    },
    picClose() {
      this.picturePlayback = false;
    },
    jfPicClose() {
      this.jfPlayVideo = false;
    },
    tyPicClose() {
      this.tyPlayVideo = false;
    },
    takePhoto() {
      for (let dev of this.currVideoDev) {
        this.currDevicePhotoUrl.push(
          this.$serverUrl +
          "/device/getLatestWebCamImage?pid=" +
          dev.pid +
          "&did=" +
          dev.did +
          "&token=" +
          this.$getToken()
        );
      }
    },
    lookupConfItem(items, name) {
      if (items) {
        for (let i in items) {
          let item = items[i];
          if (item.name == name) {
            return item;
          }
        }
      }

      return null;
    },
    closeVideo() {
      // console.log("销毁了。。。。");
      this.videoDisplay = false;
      this.$nextTick();
    },
    openVideo(dev) {
      let me = this;
      // 获取摄像头参数
      this.$http(
        "/devconf/getConf",
        "get",
        {
          devPid: dev.pid,
          devDid: dev.did,
        },
        (resp2) => {
          if (resp2.data.length == 0) {
            me.$toast.add({
              severity: "warn",
              summary: "警告",
              detail: "无可播放的资源！",
              life: 3000,
            });
          } else {
            //海康威视
            if (dev.pid == "webcam") {
              // 获取token
              this.$http(
                "/device/getYsToken",
                "get",
                {
                  devPid: dev.pid,
                  devDid: dev.did,
                },
                (resp) => {
                  this.videoDisplay = true;
                  this.$nextTick(() => {
                    me.$refs.ysVideo.show(
                      resp.data[0],
                      this.lookupConfItem(resp2.data, "serial_num"),
                      dev.dname + "（" + dev.pid + "-" + dev.did + "）"
                    );
                  });
                }
              );
            }
            //极飞
            if (dev.pid == "jf_webcam") {
              this.jfPlayVideo = true;
              this.$nextTick(() => {
                this.$refs.jfPlayVideo.init(resp2.data, dev.dname);
              });
            }
            //涂鸦
            if (dev.pid == 'tuya_webcam') {
              this.$http("/device/getTuyaLiveUrl", "get", { devId: dev.did }, (res => {
                this.tyPlayVideo = true;
                this.$nextTick(() => {
                  //   const parts = res.data.result.url.split("hls"); // 分割为数组 
                  //   console.log(parts,"---parts");
                  // this.$refs.tyPlayVideo.url='api/hls'+parts[1];
                  this.$refs.tyPlayVideo.url = res.data.result.url;
                  this.$refs.tyPlayVideo.initHls(resp2.data, dev.dname);
                });
              }))
            }
          }

        }
      );
    },
    drawByKpi(devObj, kpi, kpiCname, valueUnit) {
      this.currKpiName[devObj.pid + "-" + devObj.did] = kpi;
      this.drawChart(devObj, kpi, kpiCname, valueUnit);
      this.more = {};
    },
    drawChart(devObj, kpiEname, kpiCnamme, valueUnit) {
      console.log(devObj.did);
      this.firstSign++;
      // console.log(this.currKpiName[devObj.pid + "-" + devObj.did])
      console.log(devObj);
      if (this.firstSign <= this.currDevData.length) {
        this.kpiName[devObj.did] = devObj.kpiName[0].cname;
      } else {
        this.kpiName[devObj.did] = kpiCnamme;
      }

      // 基于准备好的dom，初始化echarts实例
      var myChart = this.echarts.init(
        document.getElementById("main_" + devObj.pid + "_" + devObj.did)
      );

      myChart.showLoading({
        text: "正在加载图表...",
        color: "rgba(18, 162, 93, 1)",
        textColor: "rgba(18, 162, 93, 1)",
        maskColor: "rgba(18, 162, 93, 0.3)",
      });
      // console.log(devObj)
      // 如果channel参数为空，则查询所有通道的数据
      let params = {
        projId: this.currProjData.id,
        pid: devObj.pid,
        did: devObj.did,
        kpiNamme: kpiEname,
      };

      this.$http("/kpi/getEChartsData", "get", params, (resp) => {
        // console.log(resp.data)
        // 默认规则
        // 处理timelist，如果默认时分秒都是00:00:00，则省略所有timelist的时分秒
        let isAllTimeZero = true;
        for (let i in resp.data.timeList) {
          let time = resp.data.timeList[i];
          if (!time.endsWith("00:00:00")) {
            isAllTimeZero = false;
          }
        }
        if (isAllTimeZero) {
          for (let i in resp.data.timeList) {
            resp.data.timeList[i] = resp.data.timeList[i]
              .replace("00:00:00", "")
              .trim();
          }
        }

        // 找到记录的最后七天的起始时间点
        let endTime = null;
        let startTime = null;
        // 考虑部分数据返回为空的情况
        if (resp.data.timeList.length > 0) {
          endTime = new Date(
            resp.data.timeList[resp.data.timeList.length - 1].replace(/ /g, "T")
          ).getTime();

          startTime = endTime - 7 * 24 * 60 * 60 * 1000;
          for (let i in resp.data.timeList) {
            let time = resp.data.timeList[i];
            let dateObj = new Date(time.replace(/ /g, "T"));
            if (
              typeof startTime == "number" &&
              dateObj.getTime() >= startTime
            ) {
              startTime = time;
            }
            if (typeof endTime == "number" && dateObj.getTime() >= endTime) {
              endTime = time;
            }
          }
        }

        let option = {
          title: {
            // text: '未来一周气温变化',
            // subtext: '纯属虚构'
          },
          tooltip: {
            trigger: "axis",
            formatter(params) {
              let html = params[0].axisValue + "<br/><br/>";
              for (let i in params) {
                let record = params[i];
                html +=
                  record.seriesName +
                  "：" +
                  record.value +
                  " " +
                  valueUnit +
                  "<br/>";
              }

              return html;
            },
          },
          legend: {
            data: [],
          },
          dataZoom: [],
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: resp.data.timeList,
          },
          yAxis: {
            type: "value",
          },
          series: [],
        };

        // 设置默认时间范围
        if (startTime && endTime) {
          option.dataZoom.push({
            type: "slider",
            startValue: startTime,
            endValue: endTime,
          });
        } else {
          option.dataZoom.push({
            type: "slider",
          });
        }

        for (let i in resp.data.channel) {
          let c = resp.data.channel[i];

          let dataList = resp.data[c];
          option["series"].push({
            name: c,
            type: "line",
            data: dataList,
          });

          if (c && dataList.length > 0) {
            option.legend.data.push(c);
          }
        }

        // 保存每个设备的最新图标绘制数据，在下载时使用
        this.currChartOption[devObj.pid + "-" + devObj.did] = option;
        this.currChartOption[devObj.pid + "-" + devObj.did]["cname"] =
          kpiCnamme;

        // 使用指定的配置项和数据显示图表
        myChart.setOption(option);

        myChart.hideLoading();
      });
    },
    loadData() {
      let me = this;
      if (this.$route.query.projId) {
        this.$http(
          "/project/get",
          "get",
          { id: this.$route.query.projId },
          function(response) {
            // console.log(response.data);
            if (response.data) {
              me.currProjData = response.data;

              if (response.data.planId) {
                me.getSuggest(response.data.planId);
              } else {
                let infos = {
                  center: [
                    me.currProjData.positionLon,
                    me.currProjData.positionLat,
                  ],
                };
                me.$nextTick(() => {
                  me.$refs.showMap.update(infos);
                });
              }
            }
          }
        );
        // 查询该项目的天气数据
        this.$http(
          "/weather/getByProjId",
          "get",
          { projId: this.$route.query.projId },
          function(response) {
            // 天气数据可能为空，因此需要判断，否则会导致页面访问null对象
            if (response.data) {
              me.currWeather = response.data;
              me.loading = true
            }
          }
        );

        // 查询该项目的设备数据
        this.$http(
          "/device/getDevData",
          "get",
          { id: this.$route.query.projId },
          function(response) {
            if (response.data) {
              me.show = false;
            } else {
              me.show = true;
            }

            me.currDevData = []
            me.currVideoDev = [];
            console.log(response.data)
            for (let item of response.data) {
              if (item.pid === "jf_webcam" || item.pid === "webcam" || item.pid === "tuya_webcam") {
                me.currVideoDev.push(item);
                if (item.isAi === 0) {
                  me.currDevData.push(item)
                }
              } else {
                me.currDevData.push(item)
              }

            }
            console.log(me.currVideoDev)
            // me.currDevData = response.data;
            if (me.currDevData.length > 0) {
              me.loadingOver = true;
            } else {
              me.projectDataShow = false;
            }
            // 对设备数据排序，将摄像头设备排在第一位（如有）
            me.currDevData.sort((a) => {
              if (a.pid == "jf_webcam" || a.pid == "webcam" || "tuya_webcam") {
                return -1;
              } else {
                return 1;
              }
            });
            //me.currVideoDev = [];
            // 将摄像头设备信息单独存储到变量中
            // for (let i in me.currDevData) {
            //   let dev = me.currDevData[i];
            //   if (dev.pid == "jf_webcam" || dev.pid == "webcam" || "tuya_webcam") {
            //     me.currVideoDev.push(dev);
            //   }
            // }
            me.length = me.currVideoDev.length - 4;
            // 如果包含摄像头，则抓取试试图片
            if (me.currVideoDev.length > 0) {
              me.takePhoto();
            }
            console.log(me.currDevData)
            // 需要延迟，等待页面dom元素加载完成
            setTimeout(() => {
              for (let i in me.currDevData) {
                let dev = me.currDevData[i];
                // console.log(me.currDevData[i])
                // 默认显示第一个图表
                if (dev && dev.kpiName && dev.kpiName.length > 0) {
                  me.drawByKpi(
                    dev,
                    dev.kpiName[0].ename,
                    dev.kpiName[0].cname,
                    dev.kpiName[0].valueUnit
                  );
                }
              }
            }, 250);
          }
        );
      }
      // 查询项目主信息
    },
    back() {
      this.$router.push(this.$route.query.from);
    },
    viewDetail() {
      this.$refs.op.toggle(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.selectedModule {
  color: #12a25d;
  border-bottom: 2px solid #12a25d;
}

.selectUl {
  height: 35px;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  border-bottom: 1px solid #f3f5f9;
}

.selectUl > li {
  height: 100%;
  margin: 0 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.blockWidth {
  width: calc(50% - 7px) !important;
}

.day-highlight {
  border: 1px solid rgba(18, 162, 93, 1);
  border-radius: 50px;
  color: green;
  background-color: rgba(18, 162, 93, 0.1);
}

.day-normal {
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 50px;
  color: #ccc;
}

.field-name {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
}

.scrollBar {
  width: 190px;
  height: 390px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0 0 0 10px;
  flex-direction: column;
  border-right: 1px solid #d9d9d9;
  //border-bottom: 1px double #d9d9d9;
}

.scrollBar::-webkit-scrollbar {
  display: none;
}

.omitIco {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 190px;
  height: 40px;
  padding: 0px 18px;
  line-height: 22px;
  background: #ffffff;
  overflow: hidden;
  text-align: center;
  border: 1px solid #d9d9d9;
  box-shadow: 0px -2px 2px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.border-green:before {
  content: "";
  width: 80%;
  position: absolute;
  bottom: 0;
  left: 10%;
  height: 2px;
  background-color: #12a25d;
}

.WeatherCard {
  width: 100%;
  padding: 8px 8px 17px 8px;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  display: flex;
}

.WeatherCard > img {
  width: 48px;
  height: 48px;
}

.WeatherCard > div {
  width: calc(100% - 48px);
  padding-left: 10px;
  text-align: center;
}

.dateButton {
  width: 40%;
  margin: 20px 30% 0;
  text-align: center;
}

.addr {
  //overflow: hidden;
  //text-overflow: ellipsis;
  //display: -webkit-box;
  //-webkit-line-clamp: 1;
  //-webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 40px;
  cursor: default;
}

.scrollBar::-webkit-scrollbar {
  width: 0;
}

.title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 13px;
}

.content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 17px;
}

.line {
  position: relative;
}

.line:before {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  height: 60px;
  top: 32px;
  background-color: rgba(217, 217, 217, 1);
}

.more {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 190px;
  width: 180px;
  z-index: 99;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background-color: #ffffff;
}

.c3 {
  width: calc(37.5% - 10px);
}

.c2 {
  width: calc(50% - 7px);
}

.chatIcon {
  width: 70px;
  height: 100px;
  border: 1px solid #79828a;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  right: 10px;
  top: 70vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 0;
  cursor: pointer;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 204px;
}

.loader {
  border: 6px solid #f3f3f3;
  /* Light grey */
  border-top: 6px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}</style>
