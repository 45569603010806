<template>
  <div class="card" style="padding: 1rem 3rem 2rem 3rem">
    <h5 class="p-text-bold p-d-flex p-justify-between p-align-center">专家建议</h5>
    <div class="suggest-card" style="position:relative">
      <div class="suggest-line">
        <span class="suggest-line-item p-col-6">最佳种植时间：{{ suggest ? suggest.bestTime : '-' }}月份</span>
        <span class="suggest-line-item p-col-3">行间距：{{ suggest ? suggest.hangjianju : '-' }}cm</span>
        <span class="suggest-line-item p-col-3">沟宽（穴直径）：{{ suggest ? suggest.goukuan : '-' }}cm</span>
      </div>
      <div class="suggest-line">
        <span class="suggest-line-item p-col-6">推荐肥料：
          <span class="p-mr-3">{{ suggest ? suggest.fertilizerType : '-' }}</span>
          <span v-if="suggest&&suggest.fertilizerList.length>0" v-for="item in suggest.fertilizerList">
            {{ item.name }}{{ item.num }}斤/亩
          </span>
        </span>
        <span class="suggest-line-item p-col-3">株间距：{{ suggest ? suggest.zhujianju : '-' }}cm</span>
        <span class="suggest-line-item p-col-3">沟深（穴深）：{{ suggest ? suggest.goushen : '-' }}cm</span>
      </div>
      <span v-if="buttonsInfo.xzjyBt" class="record-button record-button-update" @click="userSuggest()" style="position:absolute;top:3rem;right:2rem"><i class="pi pi-send"></i></span>
      <div id="soilpreSuggest">
        <div class="comment" style="padding: 0;" v-for="comm in suggestList">
          <span class="p-mr-3">{{ new Date(comm.time).toLocaleDateString() }}:</span>
          <span style="word-wrap:break-word;word-break:break-all;">{{ comm.comment }}</span>
        </div>
      </div>
      <div v-if="suggestList&&suggestList.length>6" style="width:100%;text-align:center;height:1.5rem;cursor:pointer">
        <i v-if="showChevron" class="pi pi-chevron-down p-mr-2" style="color: #12A25D" @click="AllSuggest"></i>
        <i v-else class="pi pi-chevron-up p-mr-2" style="color: #12A25D" @click='dropSuggest'></i>
      </div>
    </div>
    <h5 class="p-text-bold p-d-flex p-justify-between p-align-center">农事记录
      <Button v-if="buttonsInfo.xznsBt" class="p-button-outlined" @click="$router.push({path:'/soilpre/land',query: {projId: projectId}})">
        记录
      </Button>
    </h5>
    <div v-if="!list || list.length===0" class="p-text-center">
      暂无记录
    </div>
    <div v-else v-for="(item,index) in list" class="record">
      <div class="record-card">
        <div class="record-img" :style="{background:item.images&&item.images.length>0?null:'#EDEFF2'}">
          <my-image style="height: 100%" :src="item.images&&item.images.length>0?($minioUrl+item.images[0].imgPath):empty_img" />
        </div>
        <div class="record-content">
          <strong class="record-type">{{ item.typem ? item.typem : item.type }}</strong>
          <div class="record-line">
            <span class="record-line-item"><i class="pi pi-user p-mr-2" style="color: #12A25D"></i>{{ item.opeName
            }}</span>
            <span class="record-line-item"><i class="pi pi-clock p-mr-2" style="color: #12A25D"></i>
              {{ new Date(item.opeTime).toLocaleDateString() }}
            </span>
          </div>
          <div class="record-line" v-if="item.typem==='草种准备'">
            费用：{{ item.price }}元&nbsp;&nbsp;&nbsp;&nbsp;
            准备内容：{{ item.content == 0 ? '草种选择' : (item.content == 1 ? ' 草种截断' : (item.content == 2 ? '催芽' : null)) }}
            &nbsp;&nbsp;&nbsp;&nbsp;
            截断方式：{{ item.type == 0 ? '扦插种植' : (item.type == 1 ? '育苗移栽' : (item.type == 2 ? '平地条栽' : (item.type == 3
              ? '坡地条栽'
              : null))) }}&nbsp;&nbsp;&nbsp;&nbsp;
            价格：{{ item.price }}元
          </div>
          <div class="record-line" v-if="item.type==='施用底肥'">
            费用：{{ item.price }}元&nbsp;&nbsp;&nbsp;&nbsp;
            肥料类型：{{ item.fertilizerType == 0 ? '化肥' : '农家肥' }}
            肥料：<span v-for="fer in item.fertilizeLists">
              {{ fer.name }}{{ fer.kilo }}公斤
            </span>
          </div>
          <div class="record-line" v-if="item.type==='土地准备'">
            作业方式：{{ item.workType == 0 ? '人工' : '机械' }}&nbsp;&nbsp;&nbsp;&nbsp;
            准备内容：
            {{ item.content == 0 ? '清除杂草' : (item.content == 1 ? ' 翻耕(旋耕)' : (item.content == 2
              ? '挖排涝渠'
              : (item.content == 3 ? '通道规划' : null))) }}&nbsp;&nbsp;&nbsp;&nbsp;
            价格：{{ item.price }}元
          </div>
        </div>
        <div class="record-handle">
          <span v-if="buttonsInfo.xzplBt" class="record-button record-button-update" @click="comment(item)"><i class="pi pi-send"></i></span>
          <span v-if="buttonsInfo.xgnsBt" class="record-button record-button-update" @click="update(item)"><i class="pi pi-pencil"></i></span>
          <span v-if="buttonsInfo.scnsBt" class="record-button record-button-delete" @click="deleteItem(item)"><i class="pi pi-trash"></i></span>
        </div>
      </div>
      <div class="comment" v-for="comm in commentList.filter(one=>{
        return (item.typem ? item.typem : item.type)==one.recTable && item.id===one.recId })">
        <span class="p-mr-3">{{ new Date(comm.time).toLocaleDateString().replaceAll('/', '-') }}</span>专家评论：
        {{ comm.comment }}
      </div>
    </div>
  </div>
</template>

<script>
import myImage from "@/views/modules/chat/myImage";
import AddComment from "@/views/modules/data/overview/addComment";

export default {
  data() {
    return {
      empty_img: require("@/assets/images/background.png"),
      list: null,
      suggest: null,
      types: [],
      roleCode: null,
      suggestList: {},
      showChevron:true,
    };
  },
  components: { AddComment, myImage },
  props: {
    projectId: {
      type: Number,
    },
    commentList: {},
    buttonsInfo:{},
  },
  mounted() {
    this.$nextTick(()=>{
      if (document.getElementById("soilpreSuggest")) {
        document.getElementById("soilpreSuggest").style.maxHeight = "18rem";
        document.getElementById("soilpreSuggest").style.overflow = "hidden";
      }
    })
    this.roleCode = JSON.parse(localStorage.getItem("userinfo")).user.roleCode;
    if (localStorage.getItem("suggestInfo" + this.projectId)) {
      this.suggest = JSON.parse(
        localStorage.getItem("suggestInfo" + this.projectId)
      );
    }
    this.getList();
  },
  methods: {
    AllSuggest(){
      this.showChevron=false
      if (document.getElementById("soilpreSuggest")) {
        document.getElementById("soilpreSuggest").style.maxHeight = "";
        document.getElementById("soilpreSuggest").style.overflow = "visible";
      }
    },
    dropSuggest(){
      this.showChevron=true
      if (document.getElementById("soilpreSuggest")) {
        document.getElementById("soilpreSuggest").style.maxHeight = "18rem";
        document.getElementById("soilpreSuggest").style.overflow = "hidden";
      }
    },
    userSuggest() {
      let userId = JSON.parse(localStorage.getItem("userinfo")).user.id;
      let time = new Date();
      const data = {
        type: "整地准备",
        user_id: userId,
        projectId: this.projectId,
        time: time,
      };
      this.$emit("suggest", data);
    },
    comment(item) {
      const data = {
        recId: item.id,
        recTable: item.typem ? item.typem : item.type,
      };
      this.$emit("comment", data);
    },
    getList() {
      this.$axios
        .get("/recSoilpreLand/getAllRecord/" + this.projectId)
        .then((res) => {
          this.list = res.data;
          for (let i = 0; i < res.data.length; i++) {
            this.types.push(
              res.data[i].typem ? res.data[i].typem : res.data[i].type
            );
          }
        });
    },
    update(data) {
      const urlList = {
        土地准备: "/soilpre/land",
        草种准备: "/soilpre/grass",
        施用底肥: "/soilpre/fertilizeres",
      };

      this.$router.push({
        path: urlList[data.typem || data.type],
        query: { id: data.id, projId: this.$route.query.projId },
      });
    },
    deleteItem(data) {
      this.$confirm.require({
        message: '您确定要删除这条"' + data.type + '"吗?',
        header: "删除本条数据",
        icon: "pi pi-info-circle",
        acceptLabel: "确定",
        accept: () => {
          const urlList = {
            土地准备: "/recSoilpreLand/del/",
            草种准备: "/recSoilpreGrass/del/",
            施用底肥: "/recSoilpreFertilizer/del/",
          };
          this.$axios
            .delete(urlList[data.typem || data.type] + data.id)
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "删除成功",
                life: 3000,
              });
              this.getList();
            });
        },
        rejectLabel: "取消",
        reject: () => {
          this.$confirm.close();
        },
      });
    },
  },
};
</script>

<style scoped>
</style>