<template>
  <div class="card" style="padding: 2rem 3rem">
    <h5 class="p-text-bold p-d-flex p-justify-between p-align-center">专家建议</h5>
    <div class="suggest-card" style="position:relative">
      <div class="suggest-line">
        <span class="suggest-line-item p-col-6">最佳种植时间：{{ suggest ? suggest.bestTime : '-' }}月份</span>
        <span class="suggest-line-item p-col-3">行间距：{{ suggest ? suggest.hangjianju : '-' }}cm</span>
        <span class="suggest-line-item p-col-3">沟宽（穴直径）：{{ suggest ? suggest.goukuan : '-' }}cm</span>
      </div>
      <div class="suggest-line">
        <span class="suggest-line-item p-col-6">推荐传感器：
          <span v-if="suggest&&suggest.sensorList.length>0" v-for="item in suggest.sensorList">
            【{{ item.name }}】{{ item.type }}{{ item.num }}台；
          </span>
        </span>
        <span class="suggest-line-item p-col-3">株间距：{{ suggest ? suggest.zhujianju : '-' }}cm</span>
        <span class="suggest-line-item p-col-3">沟深（穴深）：{{ suggest ? suggest.goushen : '-' }}cm</span>
      </div>
      <span v-if="buttonsInfo.xzjyBt" class="record-button record-button-update" @click="userSuggest()" style="position:absolute;top:3rem;right:2rem"><i class="pi pi-send"></i></span>
      <div id="growSuggest">
        <div class="comment" style="padding: 0" v-for="comm in suggestList">
          <span class="p-mr-3">{{ new Date(comm.time).toLocaleDateString() }}:</span>
          <span style="word-wrap:break-word;word-break:break-all;">{{ comm.comment }}</span>
        </div>
      </div>
      <div v-if="suggestList&&suggestList.length>6" style="width:100%;text-align:center;height:1.5rem;cursor:pointer">
        <i v-if="showChevron" class="pi pi-chevron-down p-mr-2" style="color: #12A25D" @click="AllSuggest"></i>
        <i v-else class="pi pi-chevron-up p-mr-2" style="color: #12A25D" @click='dropSuggest'></i>
      </div>
    </div>
    <h5 class="p-text-bold p-d-flex p-justify-between p-align-center">农事记录
      <Button v-if="buttonsInfo.xznsBt" class="p-button-outlined" @click="$router.push({path:'/grow/emergence',query: {projId: projectId}})">
        记录
      </Button>
    </h5>
    <div v-if="!list || list.length===0" class="p-text-center">
      暂无记录
    </div>
    <div v-else class="record" v-for="(item,index) in list">
      <div class="record-card">
        <div class="record-img" :style="{background:item.images&&item.images.length>0?null:'#EDEFF2'}">
          <my-image style="height: 100%" :src="item.images&&item.images.length>0?($minioUrl+item.images[0].imgPath):empty_img" />
        </div>
        <!-- <div class="record-img">
          <img :src="item.images&&item.images.length>0?($minioUrl+item.images[0].imgPath):null"/>
        </div> -->
        <div class="record-content">
          <strong class="record-type">{{ item.type }}</strong>
          <div class="record-line">
            <span class="record-line-item"><i class="pi pi-user p-mr-2" style="color: #12A25D"></i>{{ item.opeName
            }}</span>
            <span class="record-line-item"><i class="pi pi-clock p-mr-2" style="color: #12A25D"></i>
              {{ new Date(item.opeTime).toLocaleDateString() }}
            </span>
          </div>
          <div class="record-line" v-if="item.type==='出苗记录'">出苗率：{{ item.emergenceRate }}%</div>
          <div class="record-line" v-if="item.type==='拔节记录'">高度：{{ item.height }}cm</div>
          <div class="record-line" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis" v-if="item.type==='植株测量'">
            测量编号：{{ item.no }}&nbsp;&nbsp;&nbsp;&nbsp;
            丛重：{{ item.congzhong }}克&nbsp;&nbsp;&nbsp;&nbsp;
            分蘖数：{{ item.fennieshu }}根&nbsp;&nbsp;&nbsp;&nbsp;
            单株高：{{ item.danzhugao }}cm&nbsp;&nbsp;&nbsp;&nbsp;
            单株重：{{ item.danzhuzhong }}克&nbsp;&nbsp;&nbsp;&nbsp;
            单株茎直径：{{ item.danzhujingzhijing }}cm&nbsp;&nbsp;&nbsp;&nbsp;
            单株节数：{{ item.danzhujieshu }}节&nbsp;&nbsp;&nbsp;&nbsp;
            单株节间距：{{ item.danzhujiejianju }}cm&nbsp;&nbsp;&nbsp;&nbsp;
            叶长：{{ item.yechang }}cm&nbsp;&nbsp;&nbsp;&nbsp;
            叶宽：{{ item.yekuan }}cm&nbsp;&nbsp;&nbsp;&nbsp;
            绿叶数：{{ item.lvyeshu }}个&nbsp;&nbsp;&nbsp;&nbsp;
            黄叶数：{{ item.huangyeshu }}个&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div class="record-line" v-if="item.type==='分蘖记录'">
            最高：{{ item.rateMax }}&nbsp;&nbsp;&nbsp;&nbsp;
            最低：{{ item.rateMin }}&nbsp;&nbsp;&nbsp;&nbsp;
            平均：{{ item.rateAvg }}
          </div>
        </div>
        <div class="record-handle">
          <span v-if="buttonsInfo.xzplBt" class="record-button record-button-update" @click="comment(item)"><i class="pi pi-send"></i></span>
          <span v-if="buttonsInfo.xgnsBt" class="record-button record-button-update" @click="update(item)"><i class="pi pi-pencil"></i></span>
          <span v-if="buttonsInfo.scnsBt" class="record-button record-button-delete" @click="deleteItem(item)"><i class="pi pi-trash"></i></span>
        </div>
      </div>
      <div class="comment" v-for="comm in commentList.filter(one=>{return (item.typem ? item.typem : item.type)==one.recTable && item.id===one.recId })">
        <span class="p-mr-3">{{ new Date(comm.time).toLocaleDateString().replaceAll('/', '-') }}</span>专家评论：
        {{ comm.comment }}
      </div>
    </div>
  </div>
</template>

<script>
import myImage from "@/views/modules/chat/myImage";

export default {
  name: "soilpreRecord",
  data() {
    return {
      suggest: null,
      roleCode: null,
      empty_img: require("@/assets/images/background.png"),
      list: null,
      showChevron:true
    };
  },
  components: { myImage },
  props: {
    projectId: {
      type: Number,
    },
    commentList: {},
    suggestList: {},
    buttonsInfo:{},
  },
  mounted() {
    if (document.getElementById("growSuggest")) {
        document.getElementById("growSuggest").style.maxHeight = "18rem";
        document.getElementById("growSuggest").style.overflow = "hidden";
      }
    this.roleCode = JSON.parse(localStorage.getItem("userinfo")).user.roleCode;
    if (localStorage.getItem("suggestInfo" + this.projectId)) {
      this.suggest = JSON.parse(
        localStorage.getItem("suggestInfo" + this.projectId)
      );
    }
    this.getList();
  },
  methods: {
    AllSuggest(){
      this.showChevron=false
      if (document.getElementById("growSuggest")) {
        document.getElementById("growSuggest").style.maxHeight = "";
        document.getElementById("growSuggest").style.overflow = "visible";
      }
    },
    dropSuggest(){
      this.showChevron=true
      if (document.getElementById("growSuggest")) {
        document.getElementById("growSuggest").style.maxHeight = "18rem";
        document.getElementById("growSuggest").style.overflow = "hidden";
      }
    },
    userSuggest() {
      let userId = JSON.parse(localStorage.getItem("userinfo")).user.id;
      let time = new Date();
      const data = {
        type: "生长记录",
        user_id: userId,
        projectId: this.projectId,
        time: time,
      };
      this.$emit("suggest", data);
    },
    comment(item) {
      const data = {
        recId: item.id,
        recTable: item.typem ? item.typem : item.type,
      };
      this.$emit("comment", data);
    },
    getList() {
      this.$axios
        .get("/recGrowEmergence/getAllRecord/" + this.projectId)
        .then((res) => {
          this.list = res.data;
        });
    },
    update(data) {
      const urlList = {
        出苗记录: "/grow/emergence",
        拔节记录: "/grow/jointing",
        植株测量: "/grow/measuring",
        分蘖记录: "/grow/tiller",
      };

      this.$router.push({
        path: urlList[data.typem || data.type],
        query: { id: data.id, projId: this.$route.query.projId },
      });
    },
    deleteItem(data) {
      this.$confirm.require({
        message: '您确定要删除这条"' + data.type + '"吗?',
        header: "删除本条数据",
        icon: "pi pi-info-circle",
        acceptLabel: "确定",
        accept: () => {
          const urlList = {
            出苗记录: "/recGrowEmergence/delete/",
            分蘖记录: "/recGrowTiller/delete/",
            拔节记录: "/recGrowJointing/delete/",
            植株测量: "/recGrowMeasuring/del/",
          };
          this.$axios.delete(urlList[data.type] + data.id).then(() => {
            this.$toast.add({
              severity: "success",
              summary: "删除成功",
              life: 3000,
            });
            this.getList();
          });
        },
        rejectLabel: "取消",
        reject: () => {
          this.$confirm.close();
        },
      });
    },
  },
};
</script>

<style scoped>
</style>
