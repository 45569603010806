<template>
  <Dialog class="aa" v-model:visible="show" header="采样回放" @hide="dialogHide">
    <div style="background: black;padding-top: 20px;border-radius: 4px">
      <img :style="{'display':(isLoading?'none':'block')}" class="picture" id="mainImg" width="758" height="426.375"/>
      <div :style="{'display':(!isLoading?'none':'block')}"
           style="width:758px;line-height:426px;text-align:center;color:white;">
        <ProgressSpinner style="width:40px;height:40px" strokeWidth="6" animationDuration=".5s"/>
      </div>
      <div v-if="!isLoading" style="display: flex;align-items: center;padding:10px;">
        <div style="width: 25%;display: flex;align-items: center">
          <Button v-if="!stopFlag" @click="stopPlay"
                  class="p-button-rounded" icon="pi pi-pause" iconPos="right"/>
          <Button v-if="stopFlag" @click="beginPlay"
                  class="p-button-rounded" style="padding-left:0.3rem;" icon="pi pi-caret-right" iconPos="right"/>
          <div style="margin-left:10px;color: #FFFFFF;font-size: 12px;display: flex;justify-content: flex-end">
            {{
              currName ? (new Date(currName).toLocaleDateString() + " " + new Date(currName).toLocaleTimeString()) : ""
            }}
          </div>
        </div>

        <div style="width:75%;margin:0 10px;">

          <Slider v-model="begin" :max="imgList.length-1"
                  @slideend="slideendBegin"
                  @change="changeBegin" :step="1"
                  :min="0"/>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script>

export default {
  name: "PicturePlayback",
  data() {
    return {
      show: false,
      stopFlag: true,
      begin: -1,
      interval: null,
      imgList: [],
      imgObjCache: [],
      currName: null,
      isLoading: false,
      serialNum: null
    }
  },
  watch: {
    show() {
      if (!this.show) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    begin() {
      if (this.begin > this.imgList.length) {
        clearInterval(this.interval)
        this.interval = null
      }
    }
  },
  methods: {
    init(serialNum) {
      this.show = true
      this.serialNum = serialNum;
      this.$nextTick(() => {
        this.getData()
      })
    },
    getData() {
      let me = this;
      this.$http('/device/getWebCamTimeRange', 'get', {serialNum: this.serialNum.value}, (res) => {
        this.imgList = res.data.list;
        this.currName = this.imgList[0];
        this.isLoading = true;

        // 预加载图片

        for (let i in this.imgList) {
          let name = this.imgList[i];
          if(me.imgObjCache.length == 1){
            me.isLoading = false
            document.getElementById('mainImg').src = me.imgObjCache[0];
            me.beginPlay();
          }
          me.imgObjCache.push(this.$serverUrl + '/device/getWebCamImage?fileName=' + name + '&serialNum='+this.serialNum.value+'&token=' + this.$getToken())
          // let img = new Image();
          // img.onload = function () {
          //   me.imgObjCache.push(img);
          //
          //   if (me.imgObjCache.length == 1) {
          //     me.isLoading = false
          //     document.getElementById('mainImg').src = me.imgObjCache[0].src;
          //     me.beginPlay();
          //   }
          // };
          //img.src = this.$serverUrl + '/device/getWebCamImage?fileName=' + name + '&serialNum=F12995568&token=' + this.$getToken();
        }

      })
    },
    stopPlay() {
      clearInterval(this.interval)
      this.stopFlag = true
    },
    beginPlay() {
      clearInterval(this.interval)
      this.stopFlag = false;
      this.draw()
    },
    dialogHide() {
      clearInterval(this.interval)
      this.interval = null
      this.$emit('close', true)
    },
    changeBegin(e) {
      clearInterval(this.interval)
      this.stopFlag = true
      this.begin = e
      let mainImg = document.getElementById('mainImg');
      mainImg.src = this.imgObjCache[this.begin];
    },
    slideendBegin(e) {
      clearInterval(this.interval)
      this.stopFlag = false
      this.begin = e.value
      this.draw()
    },
    loading() {
      clearInterval(this.interval)
      let me = this
      let a = setInterval(function () {
        if (me.imgObjCache[me.begin]) {
          clearInterval(a)
          me.isLoading = false;
          me.stopFlag = false;
          me.draw()
        }
      }, 1000)

    },

    draw() {
      let mainImg = document.getElementById('mainImg');
      this.interval = setInterval(() => {
        if (this.begin < this.imgList.length) {
          this.begin++;
          this.currName = this.imgList[this.begin];
          let obj = this.imgObjCache[this.begin];
          // 判断下一个要播放的图片是否已经缓冲完毕，如果没有则等待缓冲
          if (obj) {
            mainImg.src = obj;
          } else {
            clearInterval(this.interval)
            this.stopFlag = true;
            this.isLoading = true;
            this.loading()
            // 等待2秒
            // setTimeout(()=>{
            //   this.isLoading = false;
            //   this.stopFlag = false;
            //   this.draw()
            // },3000)
          }
        }

        if (this.begin == this.imgList.length) {
          clearInterval(this.interval);
          this.stopFlag = true;
          this.begin = 0;
          this.currName = this.imgList[0];
          mainImg.src = this.imgObjCache[0].src;
        }
      }, 1000 / 3)
    }
  }
}
</script>

<style lang="scss" scoped>
.time {
  position: absolute;
  min-width: 20px;
  top: 15px;
  display: flex;
  height: 25px;
  padding: 3px;
  border-radius: 3px;
  background: #666666;
  opacity: 0.5;
  justify-content: center;
}
</style>
<style lang="scss">

.aa .p-slider .p-slider-range {
  background: #12A25D;
}

.aa .p-slider .p-slider-handle {
  border-color: #12A25D;
}

.aa .p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #12A25D;
  border-color: #12A25D;
}
</style>
