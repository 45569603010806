<template>
  <Dialog header="新增建议" style="width: 30vw" v-model:visible="display" @hide="$emit('close')">
    <Textarea style="width: 100%" auto-resize="true" :class="{'p-invalid':(v$.form.comment.$invalid && submitted)}" placeholder="请输入..." v-model="form.comment"></Textarea>
    <p class="err-message" v-if="v$.form.comment.$invalid && submitted">不能提交空建议</p>
    <template #footer>
      <Button class="p-button-outlined" @click="display=false">取消</Button>
      <Button @click="submit">提交</Button>
    </template>
  </Dialog>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "addComment",
  data() {
    return {
      display: false,
      submitted: false,
      form: {
        projectId: null,
        userId: null,
        type: null,
        time: null,
        comment: null,
      },
    };
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  validations() {
    return {
      form: {
        // id:{required},
        projectId: { required },
        userId: { required },
        type: { required },
        time: { required },
        comment: { required },
        // recTable: { required },
        // comment: { required },
        // recId: { required },
        // userId: { required },
        // time: { required },
        // projectId: { required },
      },
    };
  },
  methods: {
    init(data) {
      this.display = true;
      this.form.projectId = Number(data.projectId);
      this.form.userId = Number(data.user_id);
      this.form.type = data.type;
      this.form.time = data.time;
    },
    submit() {
      this.submitted = true;
      if (this.v$.$invalid) {
        return;
      }
      this.$axios
        .post("/rec_suggest_comment/saveOrUpdate", this.form)
        .then((res) => {
          if (!res.data) {
            this.$toast.add({
              severity: "success",
              summary: "添加成功",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "warn",
              summary: res.data,
              life: 3000,
            });
          }
          this.display = false;
        });
    },
  },
};
</script>

<style scoped>
</style>