<template>
  <Dialog header="新增评论" style="width: 30vw" v-model:visible="display" @hide="$emit('close')">
    <Textarea style="width: 100%" auto-resize="true" :class="{'p-invalid':(v$.form.comment.$invalid && submitted)}"
              placeholder="请输入..." v-model="form.comment"></Textarea>
    <p class="err-message" v-if="v$.form.comment.$invalid && submitted">不能提交空评论</p>
    <template #footer>
      <Button class="p-button-outlined" @click="display=false">取消</Button>
      <Button @click="submit">提交</Button>
    </template>
  </Dialog>
</template>

<script>
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';

export default {
  name: 'addComment',
  data() {
    return {
      display: false,
      submitted: false,
      form: {
        recTable: null,
        comment: null,
        recId: null,
        userId: null,
        time: null,
        projectId: null,
      },
    };
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  validations() {
    return {
      form: {
        recTable: {required},
        comment: {required},
        recId: {required},
        userId: {required},
        time: {required},
        projectId: {required},
      },
    };
  },
  methods: {
    init(data) {
      this.display = true;
      this.form.projectId = data.projectId;
      this.form.userId = JSON.parse(localStorage.getItem('userinfo')).user.id;
      this.form.recId = data.recId;
      this.form.recTable = data.recTable;
      this.form.time = new Date();
    },
    submit() {
      this.submitted = true;
      if (this.v$.$invalid) {
        return;
      }
      this.$axios.post('/rec_comment/add', this.form).then(res => {
        if (!res.data) {
          this.$toast.add({
            severity: 'success',
            summary: '添加成功',
            life: 3000,
          });

        } else {
          this.$toast.add({
            severity: 'warn',
            summary: res.data,
            life: 3000,
          });
        }
        this.display = false;
      });
    },
  },
};
</script>

<style scoped>

</style>